// Useful in computeds for avoiding hydration mismatches
// Only true on the client after hydration is done
export function useHasHydrated() {
  const hasHydrated = ref(false)

  onNuxtReady(() => {
    hasHydrated.value = true
  })

  return hasHydrated
}
